import React, { useState, useRef, useEffect } from "react";
import TrackingTag from "components/TrackingTag/TrackingTag";
import styles from "./PageElements.module.scss";
import axios from "axios";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";

const trackTagProps = {
	config: {
		pageName: "speculasies",
		subsite: false,
		chapters: [],
		siteId: 80,
		initialisationCode: 630429,
		initialisationCodeForTest: 630430,
		siteType: "standalone",
		platform: "site",
		broadcaster: "ntr",
		idfa: false,
		program: "sinterklaasjournaal",
		eventType: "home",
	},
	customVars: {
		1: "[site]",
		2: "[ntr]",
		3: "[sinterklaasjournaal]",
		4: "[standalone]",
		6: "[speculasies]",
		7: "[general]",
	},
	settings: {
		name: "speculasies-mijn-speculasie",
		level2: 80,
	},
};

const MijnSpeculasie = () => {
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [naamInzender, setNaamInzender] = useState("");

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const isValidEmail = (email) =>
		// eslint-disable-next-line no-useless-escape
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email
		);
	
	// return true if string length is between min and max	
	const stringLengthIsBetween = (string, min, max) => {
		return string.length >= min && string.length <= max;
	};

	const textareaRef = useRef(null);

	useEffect(() => {
	  const textarea = textareaRef.current;
	  if (textarea) {
		console.log("It exists");
		const resizeTextarea = () => {
		  textarea.style.height = "";
		  textarea.style.height = `${textarea.scrollHeight}px`;
		};
		textarea.addEventListener("input", resizeTextarea);
		return () => {
		  textarea.removeEventListener("input", resizeTextarea);
		};
	  }
	}, []);

	const placeholderName = "Je naam";
	const placeholderEmail = "Je e-mailadres";
	const placeholderSpeculasie = "Ik denk dus dat...";

	const onSubmit = (data) => {
		let formdata = new FormData();
		let newData = {};
		newData["form-type"] = { value: "mail" };
		newData["name"] = { value: data.name };
		newData["email"] = { value: data.email };
		newData["message"] = { value: data.speculasie };
		formdata.append("formData", JSON.stringify(newData));

		// send data with axios
		axios
			.post("/php/forms.php", formdata)
			.then((res) => {
				console.log(res);
				console.log(res.data);
				setNaamInzender(data.name);
				setFormSubmitted(true);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return formSubmitted ? (
		<>
			{" "}
			<motion.div
				className={styles.textblock}
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0, y: "-30vh", transition: { duration: 0.4 } }}
				variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
				<h2>Beste {naamInzender}</h2>
				<p>
					Bedankt voor het inzenden van jouw speculasie. Wie weet zien we die
					binnenkort wel terug in de uitzending! Heb je nog meer speculasies?
					Stuur dan vooral nog een mail.{" "}
				</p>
				<p>Maar laten we ze niet te bruin bakken!</p>
				<p>
					Met vriendelijke groet,
					<br />
					Redactie Speculasies
				</p>
			</motion.div>
		</>
	) : (
		<>
			<motion.div
				className={styles.textblock}
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0, y: "-30vh", transition: { duration: 0.4 } }}
				variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
				<h2>Mijn speculasie</h2>
				<p>
					Heb je zelf een ontzettende krokante speculasie? Stuur hem dan naar ons, misschien gaan we het erover hebben aan tafel!
				</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div>
						<label>Naam</label>
						<input
						type="text"
							defaultValue=""
							{...register("name", { required: "Naam is verplicht" })}
							placeholder={placeholderName}
						/>
					</div>
					<div>
						<label>E-mailadres</label>
						<input
							type="text"
							defaultValue=""
							{...register("email", {
								required: "E-mailadres is verplicht",
								validate: {
									checkEmail: (v) =>
										isValidEmail(v) || "Dit is geen geldig e-mailadres",
								},
							})}
							placeholder={placeholderEmail}
						/>
					</div>
					<div>
						<label>Speculasie</label>
						<textarea
						 	ref={textareaRef}
							defaultValue=""
							{...register("speculasie", {
								required: "Je moet wel een speculasie versturen",
								onChange: (e) => {
									e.target.style.height = "";
									e.target.style.height = (e.target.scrollHeight + 2) + "px";
								},
								validate: {
									lengthCheck: (v) =>
										stringLengthIsBetween(v, 10, 1000) || "Je speculasie moet tussen de 10 en 1000 tekens lang zijn",
								},
							})}
							placeholder={placeholderSpeculasie}
						/>
					</div>
					<div>
						<ul>
							{errors.name && <p>{errors.name.message}</p>}
							{errors.email && <p>{errors.email.message}</p>}
							{errors.speculasie && <p>{errors.speculasie.message}</p>}
						</ul>
					</div>

					<input type="submit" />
				</form>
			</motion.div>
			<TrackingTag tag={trackTagProps} />
		</>
	);
};

export default MijnSpeculasie;
