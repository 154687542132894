import React, { cloneElement } from "react";
import { AnimatePresence } from "framer-motion";
import { useLocation, useRoutes } from "react-router-dom";
import "./App.css";
import Layout from "components/Layout/Layout";
import Home from "pages/Home";
import MijnSpeculasie from "pages/MijnSpeculasie";

function App() {
	const element = useRoutes([
		{
			path: "/",
			element: <Home />,
		},
		{
			path: "/mijnspeculasie",
			element: <MijnSpeculasie />,
		},
	]);

	const location = useLocation();

	if (!element) return null;

	return (
		<Layout>
			<AnimatePresence mode="wait">
				{cloneElement(element, { key: location.pathname })}
			</AnimatePresence>
		</Layout>
	);
}

export default App;
