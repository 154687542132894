import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./Header.module.scss";
import Navigation from "components/Navigation/Navigation";
import logo from "../../assets/images/speculasies.png";
import speculasie from "../../assets/images/speculasie-topleft.png";
import ntrlogo from "../../assets/images/ntrlogo.png";
import zapplogo from "../../assets/images/zapplogo.png";

const Header = () => {
	return (
		<div className={styles.header}>
			<div className={styles.zenderbalk}>
				<a
					href="https://www.ntr.nl"
					aria-label="Naar de NTR site"
					target="_blank"
					rel="noopener noreferrer">
					<img src={ntrlogo} alt="NTR" className={styles.zenderlogo} />
				</a>
				<a
					href="https://www.zapp.nl"
					aria-label="Naar de ZAPP site"
					target="_blank"
					rel="noopener noreferrer">
					<img src={zapplogo} alt="ZAPP" className={styles.zenderlogo} />
				</a>
			</div>
			<div className={styles.headerbalk}>
				<h1>
					<img src={logo} alt="SPECULASIES" />
				</h1>
			</div>
			<Navigation />
			<AnimatePresence>
				<motion.img
					initial={{ y: "100vh" }}
					animate={{ y: "0" }}
					src={speculasie}
					alt="Een speculasie"
					className={styles.speculasieTopLeft}
				/>
			</AnimatePresence>
		</div>
	);
};

export default Header;
