import {memo} from 'react';
import PropTypes from 'prop-types';

const TrackingTag = (props) => {

  let pageName = false;
  let cmsId = '';

    const { tag, section, sectionId } = props;
    if (tag) {
      const settings = Object.assign({}, tag.settings);
      if (section) {
        settings.chapter2 = settings.name;
        settings.name = section;
      }
      const customVars = Object.assign({}, tag.customVars);
      if (sectionId) {
        customVars['8'] = `[${sectionId}]`;
        cmsId = sectionId;
      }

      // for piano analytics
      // eslint-disable-next-line no-undef
      if ((window.pa || process.env.NODE_ENV === 'development') && pageName !== settings.name) {
        pageName = settings.name;
        let customVar = tag.customVars;
        const paChapter1 = settings.chapter2 ? settings.chapter2 : 'speculasies';
        const customVarPlatform = customVar[1]
            ? customVar[1].replace(/\[/, '').replace(/\]/, '')
            : '';
        const customVarBroadcaster = customVar[2]
            ? customVar[2].replace(/\[/, '').replace(/\]/, '')
            : '';
        const customVarProgramma = customVar[3]
            ? customVar[3].replace(/\[/, '').replace(/\]/, '')
            : '';
        const customVarHomepage = customVar[6]
            ? customVar[6].replace(/\[/, '').replace(/\]/, '')
            : '';
        const customNMO = customVarHomepage === 'home' ? 1 : 0;

        if( process.env.NODE_ENV === 'development' ) {
          console.log ({
            page: `${pageName}`,
            page_chapter1: `${paChapter1}`,
            programma: `${customVarProgramma}`,
            platform: `${customVarPlatform}`,
            broadcaster: `${customVarBroadcaster}`,
            contextID: `${process.env.REACT_APP_PIANO_CONTEXT_ID}`,
            cms_id: `${cmsId}`,
            c_nmo_02: `${customNMO}`
          }

          )

        } else {

          // eslint-disable-next-line no-undef
          window.pa.sendEvent('page.display', {
            page: `${pageName}`,
            page_chapter1: `${paChapter1}`,
            programma: `${customVarProgramma}`,
            platform: `${customVarPlatform}`,
            broadcaster: `${customVarBroadcaster}`,
            contextID: `${process.env.REACT_APP_PIANO_CONTEXT_ID}`,
            cms_id: `${cmsId}`,
            c_nmo_02: `${customNMO}`
          });
        } 
      }
    }
    return null;
}

TrackingTag.propTypes = {
  tag: PropTypes.objectOf(PropTypes.any),
  sectionId: PropTypes.string,
  section: PropTypes.string,
};
TrackingTag.defaultProps = {
  tag: undefined,
  sectionId: undefined,
  section: undefined,
};

const MemoizedTrackingTag = memo(TrackingTag);

export default MemoizedTrackingTag