import React from "react";
import styles from "./Layout.module.scss";
import Header from "components/Header/Header";

const Layout = ({ children }) => {
	return (
		<>
			<div className={styles.container}>
            <div className={styles.row}>
				<div className={styles.leftmargin} />
				<div className={styles.main}>
					<Header />
					{children}
				</div>
				<div className={styles.rightmargin} />
                </div>
			</div>
		</>
	);
};

export default Layout;
