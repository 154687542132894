import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Navigation.module.scss";
import menu_homepage from "../../assets/images/menu_home.png";
import menu_mijnspeculasie from "../../assets/images/menu_mijnspeculasie.png";

const Navigation = () => {
    return (
            <nav className={styles.navigation}>
                <ul>
                    <li><NavLink className={styles.navLink} aria-label="Naar de homepage" to="/"><img src={menu_homepage} alt="Huisje" />Homepage</NavLink></li>
                    <li><NavLink className={styles.navLink}  aria-label="Stuur je eigen speculasie in" to="/mijnspeculasie"><img src={menu_mijnspeculasie} alt="Envelopje" />Mijn Speculasie</NavLink></li>
                </ul>
            </nav>
    )
}

export default Navigation;